import { Axios } from "axios";
import classNames from "./custom.module.scss";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { useEffect, useRef, useState } from "react";

export const EachCustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  typee,
  placeholder,
  indexValue,
  objName,
  mapName,
  option,
  currentValue,
  indexx,
  asteriskIconn,
  typeee,
  noopen,
  selectedEmployee,
  allEmployees,
  refreshPage,
  apiVal,
  setLocalRefresh,
  mapNameType,
  isAlreadyThere,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [otherSubCards, setOtherSubCards] = useState({});
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  //function

  async function updateRoleCosting(obj) {
    try {
      let response = await Axios.put(
        `https://coverfi.apimachine.com/api/v1/raha-lead/mapping/update/${apiVal}`,
        obj
      );
      setLocalRefresh((prev) => !prev);
      if (response?.data?.status) {
        // predefinedToast("Updated role...");
      }
    } catch (error) {
      console.log(error?.message, "Update role costing error");
    }
  }

  //renderings

  useEffect(() => {
    if (isAlreadyThere) {
      setSelectedOption(stateValue[name]);
    }
  }, [isAlreadyThere]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        minWidth:
          otherSubCards?.type === "Bulk Assign Employees List" &&
          typee === "addPolicyToAll"
            ? "600px"
            : typee === "addPolicyToAll" && isOpen
            ? "500px"
            : noopen
            ? ""
            : isOpen
            ? "250px"
            : "",
        zIndex:
          otherSubCards?.type === "Bulk Assign Employees List" &&
          typee === "addPolicyToAll"
            ? "7"
            : "",
      }}
    >
      {
        <div
          className={classNames.inputContainer}
          onClick={toggleDropdown}
          style={{
            pointerEvents: dropdown?.length < 1 ? "none" : "",
            zIndex: isOpen ? "3" : "",
            maxHeight: typee === "addPolicyToAll" ? "50vh" : "",
          }}
          ref={dropdownRef}
        >
          <span>
            <span>
              {objName === "_id" && selectedOption
                ? selectedOption
                : objName === "_id" && name === "user_reporting_manager_id"
                ? placeholder
                : mapName?.length > 0 &&
                  Array.isArray(mapName) &&
                  stateValue[name]
                ? ` ${
                    objName == "grade"
                      ? "Grade " + stateValue[name][objName]
                      : stateValue[name][mapName[objName]]
                  }`
                : option === "alreadySet"
                ? name && indexx
                  ? stateValue[name][indexx]
                  : stateValue[name] === true
                  ? "Applicable"
                  : stateValue[name] === false
                  ? "Not Applicable"
                  : stateValue[name]
                : option === "checkValue" && stateValue[name]
                ? stateValue[name]
                : selectedOption
                ? selectedOption
                : title
                ? title
                : placeholder
                ? placeholder
                : "Select an option"}
            </span>
            <span
              style={{ visibility: typeee === "removeArrow" ? "hidden" : "" }}
            >
              {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
            </span>
          </span>
          {isOpen && (
            <ul
              className="dropdown-list"
              style={{ height: typee === "addPolicyToAll" ? "50vh" : "" }}
            >
              <li
                style={{
                  display:
                    dropdown?.length <= 4 || placeholder == "Relation"
                      ? "none"
                      : "",
                }}
              >
                <input
                  ref={inputRef}
                  type="text"
                  placeholder="Search.."
                  value={searchQuery}
                  onChange={handleInputChange}
                  onClick={(event) => event.stopPropagation()}
                />
              </li>
              {typee === "arrayObj" && dropdown?.length > 0
                ? dropdown
                    ?.filter((row) => {
                      return row
                        ?.toLowerCase()
                        ?.includes(searchQuery?.toLowerCase());
                    })
                    ?.map((eachitem, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            selectOption(eachitem);
                            setState((prev) => {
                              return prev?.map((item, i) => {
                                if (i === indexValue) {
                                  return { ...item, [name]: eachitem };
                                }
                                return item;
                              });
                            });
                          }}
                          key={eachitem + index}
                        >
                          {eachitem}
                        </li>
                      );
                    })
                : typee === "single" && dropdown?.length > 0
                ? dropdown
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                      return row?.toLowerCase()?.includes(searchQueryy);
                    })
                    ?.map((eachitem, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            selectOption(eachitem);
                            console.log(eachitem, "eachitem", name);

                            if (name) {
                              setState({
                                ...stateValue,
                                [name]: eachitem,
                              });
                              // setState((prev) => {
                              //   return { ...prev, [name]: eachitem };
                              // });
                            } else {
                              setState(eachitem);
                            }
                          }}
                          key={eachitem + index}
                          style={{
                            display: eachitem === stateValue ? "none" : "",
                          }}
                        >
                          {eachitem}
                        </li>
                      );
                    })
                : typee === "objVal" && dropdown?.length > 0
                ? dropdown
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const values = Object?.values(row);
                      const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                      return values?.some((value) => {
                        if (typeof value === "string") {
                          return value?.toLowerCase()?.includes(searchQueryy);
                        }
                        return false;
                      });
                    })
                    ?.map((eachitem, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            console.log(eachitem[mapName[0]]);
                            selectOption(
                              objName === "_id" &&
                                name === "user_reporting_manager_id"
                                ? eachitem[mapName[0]]
                                : mapName?.length > 0 && Array.isArray(mapName)
                                ? mapName[0]
                                : mapName
                                ? eachitem[mapName]
                                : eachitem[objName]
                            );
                            if (
                              mapName?.length > 0 &&
                              Array.isArray(mapName) &&
                              objName === "_id" &&
                              name === "user_reporting_manager_id"
                            ) {
                              setState((prev) => {
                                return {
                                  ...prev,
                                  [name]: objName
                                    ? eachitem[objName]
                                    : eachitem,
                                };
                              });
                            } else if (
                              mapName?.length > 0 &&
                              Array.isArray(mapName)
                            ) {
                              setState((prev) => {
                                return { ...prev, [name]: eachitem };
                              });
                            } else {
                              setState({
                                ...stateValue,
                                [name]: eachitem[objName],
                              });
                            }
                          }}
                          key={eachitem[objName] + index}
                          style={{
                            flexDirection:
                              mapNameType === "flex"
                                ? ""
                                : mapName?.length > 0 && Array.isArray(mapName)
                                ? "column"
                                : "",
                            alignItems:
                              mapName?.length > 0 && Array.isArray(mapName)
                                ? "flex-start"
                                : "",
                            gap: mapNameType === "flex" ? "4px" : "",
                            marginBottom: mapNameType === "flex" ? "0" : "",
                          }}
                        >
                          {/* <img src={eachitem} alt={eachitem} /> */}
                          {mapName?.length > 0 && Array.isArray(mapName)
                            ? mapName?.map((eachItem) => {
                                return (
                                  <div key={eachitem[eachItem] + index}>
                                    {objName === "grade" && eachItem == "grade"
                                      ? `Grade ${eachitem[eachItem]}`
                                      : eachitem[eachItem]}
                                  </div>
                                );
                              })
                            : mapName
                            ? eachitem[mapName]
                            : eachitem[objName]}
                        </li>
                      );
                    })
                : typee === "obj" && dropdown?.length > 0
                ? dropdown
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const values = Object?.values(row);
                      const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                      return values?.some((value) => {
                        if (typeof value === "string") {
                          return value?.toLowerCase()?.includes(searchQueryy);
                        }
                        return false;
                      });
                    })
                    ?.map((eachitem, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            selectOption(eachitem);
                            setState({
                              ...stateValue,
                              [name]: eachitem,
                            });
                          }}
                          key={eachitem + index}
                        >
                          <img src={eachitem} alt={eachitem} />
                          {eachitem}
                        </li>
                      );
                    })
                : dropdown?.length > 0 &&
                  dropdown
                    ?.filter((row) => {
                      // Convert the row object values to an array and check if any value matches the search query
                      const values = Object?.values(row);
                      const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                      return values?.some((value) => {
                        if (typeof value === "string") {
                          return value?.toLowerCase()?.includes(searchQueryy);
                        }
                        return false;
                      });
                    })
                    ?.map((eachitem, index) => {
                      return (
                        <li
                          onClick={(event) => {
                            event.stopPropagation();
                            selectOption(eachitem?.app_name);
                            setState({
                              ...stateValue,
                              [name]: eachitem?.app_code,
                            });
                          }}
                          key={eachitem?.app_name + index}
                        >
                          <img
                            src={eachitem?.app_icon}
                            alt={eachitem?.app_name}
                          />
                          {eachitem?.app_name}
                        </li>
                      );
                    })}
              {/* {stateValue ? (
              <li
                onClick={(event) => {
                  event.stopPropagation();
                  selectOption("");
                  setState("");
                }}
                key={"allCompanies"}
              >
                All Companies
              </li>
            ) : (
              ""
            )} */}
            </ul>
          )}
        </div>
      }
    </div>
  );
};
